import * as React from "react";

import Layout from "../../components/Layout";

export default class AuthorsIndexPage extends React.Component {
  render() {
    return (
      <Layout scrollNavbar={true}>
        <div className="pt-32">
        authors
        </div>
       
      </Layout>
    );
  }
}
